import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { processUrlBackwardCompatibility } from 'components/backward-compatibility-router/index';
import Error404Page from 'app/error/Error404Page';

export default function RedirectIndexPage() {
    const navigate = useNavigate();
    const location = useLocation();
    let params = useParams();
    const [newUrl, setNewUrl] = useState<string | null>(null);

    useEffect(() => {
        let entityKey = params.entityType ?? '';
        if (!entityKey) {
            const pathParts = location.pathname.split('/');
            entityKey = pathParts[2] ?? '';
        }

        if (location.pathname.includes('/editor/index')) {
            entityKey = 'elements';
        }

        const processedUrl = processUrlBackwardCompatibility('index', entityKey, location, params);
        setNewUrl(processedUrl);
        if (processedUrl) {
            navigate(processedUrl);
            return;
        }
    }, []);

    if (newUrl === '') return <Error404Page />;

    return null;
}
