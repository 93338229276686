import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
    getLegacyViewerTypeByElementType,
    processUrlBackwardCompatibility,
    redirectToViewerByElementType,
} from 'components/backward-compatibility-router/index';
import Error404Page from 'app/error/Error404Page';

export default function RedirectViewerPage() {
    const navigate = useNavigate();
    const location = useLocation();
    let params: any = useParams();
    const [newUrl, setNewUrl] = useState<string | null>(null);

    useEffect(() => {
        let entityKey = params.entityType ?? '';

        if (!entityKey) {
            if (location.pathname.includes('/contentworkflow')) {
                entityKey = 'contentworkflow';
            }
        }

        if (
            location.pathname.includes('/index/preview/element/') ||
            location.pathname.includes('/index/preview/segment/')
        ) {
            params.elementViewType = 'preview';
            params.elementViewTypeOriginal = 'preview';
        }
        if (location.pathname.includes('/index/customview/element/')) {
            params.elementViewType = 'preview';
            params.elementViewTypeOriginal = 'customview';
        }
        if (location.pathname.includes('/index/index/element/')) {
            params.elementViewType = 'viewer';
            params.elementViewTypeOriginal = 'viewer';
            if (entityKey === 'index') {
                redirectToViewerByElementType('indexViewer', location, params);
                return;
            }
        }

        //redirect to viewer from id path like /127
        if (!entityKey && params.elementIdOnly) {
            const elementIdPath = location.pathname.match(/(\/[0-9]+)/);
            if (elementIdPath && elementIdPath[0] == location.pathname) {
                redirectToViewerByElementType('elementIdViewer', location, params);
                return;
            }
        }

        const processedUrl = processUrlBackwardCompatibility('viewer', entityKey, location, params);
        setNewUrl(processedUrl);
        if (processedUrl) {
            navigate(processedUrl);
            return;
        }
    }, []);

    if (newUrl === '') return <Error404Page />;

    return null;
}
