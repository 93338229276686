import Axios from 'axios';
import qs from 'query-string';
import { DownloadManagerHandlerProps } from 'services/DownloadManager';
import { PoolingRequestData } from 'tools/pollingService';

const baseOptions = {
    withCredentials: true,
    baseURL: process.env.REACT_APP_BACKEND_API_BASE_URL?.length ? process.env.REACT_APP_BACKEND_API_BASE_URL : '/',
};

export const instance = Axios.create({
    ...baseOptions,
    headers: {
        Accept: 'application/json',
    },
});

export const fileInstance = Axios.create({
    ...baseOptions,
    responseType: 'blob',
});

instance.interceptors.response.use(
    (response: any) => {
        if (
            'ERROR' == response?.data?.status &&
            ('Unauthorized' == response?.data?.error || 'NotFound' == response?.data?.error)
        ) {
            let url = '/auth/deny/type/' + response?.data?.type;

            if (response?.data?.params) url += '?' + new URLSearchParams(response.data.params);

            document.location = url;
        } else if (
            'ERROR' == response?.data?.status &&
            'need_redirect' == response?.data?.error &&
            response?.data?.url
        ) {
            if (document.location.href != response.data.url) document.location = response.data.url;
            else document.location.reload();
        }

        if (
            response?.headers &&
            response?.headers['content-type'] === 'text/html' &&
            'string' === typeof response?.data &&
            response?.data.indexOf('<meta http-equiv="refresh" content=') > -1
        ) {
            window.location.reload();
        }

        return response;
    },
    (err: any) => {
        return Promise.reject(err);
    },
);

//instance.get('/sanctum/csrf-cookie');

export const controlDataAPI = {
    getAsyncData(url: string) {
        return instance.get(url);
    },
};

export const devAPI = {
    getEditorImage(url: string) {
        return instance.get(url);
    },
    loadFromFiles() {
        return instance.get('/data/admin/vocabulary/load-from-files');
    },
    writeToFiles() {
        return instance.get('/data/admin/vocabulary/write-to-files');
    },
};

//Common Object for use everywhere
export const customRequestAPI = {
    get(url: string, params: any = {}): any {
        if ('undefined' === typeof params) params = {};
        const urlParams = qs.stringify(params);
        return instance.get(url + (urlParams ? '?' + urlParams : ''));
    },
    post(url: string, params: any = {}): any {
        if ('undefined' === typeof params) params = {};
        return instance.post(url, params);
    },
    delete(url: string) {
        return instance.delete(url);
    },
};

export const translationAPI = {
    sent: {} as { [key: string]: boolean },
    post(params: any = {}): any {
        if (undefined !== this.sent[params.withPrefix]) return;
        this.sent[params!.withPrefix] = true;

        return instance.post('data/content/translation', params);
    },
    async save(params: any = {}) {
        const response = await instance.post<any>(
            '/data/admin/vocabulary/' +
                (params?.application_translation_id > '' ? params?.application_translation_id : '{translationId}') +
                '/form',
            params,
        );

        return response.data;
    },
    async suggestion(params: any = {}) {
        const response = await instance.post<any>('/data/admin/vocabulary/suggestion', params);

        return response.data;
    },
    async rollback(params: any = {}) {
        const response = await instance.post<any>('/data/admin/vocabulary/rollback', params);

        return response.data;
    },
};

export const authAPI = {
    info() {
        return instance.get(`auth/info`);
    },
    login(username: string, password: string, remember?: string) {
        return instance
            .post(`auth/login`, {
                username: username,
                password: password,
                remember: remember,
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    },
    logout() {
        return instance
            .get(`auth/logout`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.error('There was an error!', error);
            });
    },
    loginAs(userId: number, returnUrl: string) {
        return instance
            .post(`/data/editor/user/${userId}/login-as`, {
                returnUrl: returnUrl,
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    },
    async loadAfterLoginPage() {
        const response = await instance.get(`auth/after-login-page`);

        return response?.data ?? {};
    },
};

export const popupAPI = {
    load(url: string, params: any = {}): any {
        const urlParams = qs.stringify(params);
        return instance.get(url + (urlParams ? '?' + urlParams : ''));
    },
};

export const monitorAPI = {
    loadSystemDateTimeData() {
        return instance.get(`data/monitor/system-datetime`);
    },
    toggleSystemNotifications(data: { status: boolean }) {
        return instance.post(`data/monitor/system-notifications`, data);
    },
    getSystemNotificationsState() {
        return instance.get(`data/monitor/system-notifications`);
    },
    getSystemStatus() {
        return instance.get(`data/monitor/status-data`);
    },
    sendTestEmail(email: string) {
        return instance.post(`data/monitor/emails-queue/sent-test-email`, { send_to: email });
    },
    sendNow() {
        return instance.post(`data/monitor/emails-queue/sent-now-email`);
    },
    clearMailQueue() {
        return instance.post(`data/monitor/emails-queue/clear-email-queue`);
    },
    getLdapStatus() {
        return instance.get(`data/monitor/status-ldap/refresh`);
    },
    getLdapConnections() {
        return instance.get(`data/monitor/status-ldap/get-connections`);
    },
    testLdap(username: string, password: string, server: string) {
        return instance.post(`data/monitor/ldap/test`, { username, password, server });
    },
};

const downloadBaseURL = 'data/download/element';
export const processDownloadResponse = function (response: any) {
    let filename = 'file';
    const disposition = response.headers['content-disposition'];
    if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
        }
    }

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const downloadFile = (url: string, front: boolean = false) => {
    const link = document.createElement('a');
    const baseUrl =
        baseOptions.baseURL.charAt(baseOptions.baseURL.length - 1) === '/'
            ? baseOptions.baseURL
            : baseOptions.baseURL + '/';
    link.href = front ? url : baseUrl + url;
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const downloadAPI = {
    element: {
        runDownload(props: DownloadManagerHandlerProps, options?: any) {
            return instance.post(`${downloadBaseURL}/${props.elementId}/${props.segmentId}/run`, {
                ...options,
                format: props.format,
            });
        },
        checkDownload(props: DownloadManagerHandlerProps, options?: any) {
            return instance.post(`${downloadBaseURL}/${props.elementId}/${props.segmentId}/check`, {
                ...options,
                format: props.format,
            });
        },
        getDownload(props: DownloadManagerHandlerProps, options?: any) {
            return fileInstance
                .post(`${downloadBaseURL}/${props.elementId}/${props.segmentId}/get`, {
                    ...options,
                    format: props.format,
                })
                .then((response) => {
                    processDownloadResponse(response);
                });
        },
    },
    export: {
        getDownload(downloadURL: string, options?: any) {
            return fileInstance.post(downloadURL, options).then((response) => {
                processDownloadResponse(response);
            });
        },
    },
};

export const pollingAPI = {
    create(data: any & PoolingRequestData) {
        const url = data?.poolingCreateURL ?? 'data/polling/create';
        const method = data?.poolingCreateMethod ?? 'POST';
        delete data.poolingCreateURL;
        delete data.poolingCreateMethod;
        if (method == 'GET') {
            return instance.get(url, data);
        }
        return instance.post(url, data);
    },
    check(runId: number) {
        return instance.get(`data/polling/check?pplId=` + runId);
    },
    run(runId: number) {
        return instance.post(`data/polling/run?pplId=` + runId);
    },
    cancel(runId: number) {
        return instance.post(`data/polling/cancel?pplId=` + runId);
    },
};

export const uploadFileAPI = {
    sendFile(url: string, file: any) {
        return instance.post(url, file);
    },
    removeFile(url: string) {
        return instance.post(url);
    },
};

export const SignOutPageAPI = {
    getData() {
        return instance.get(`/data/auth/signed-out`);
    },
};

export const changePassPageAPI = {
    getData() {
        return instance.get(`/auth/change-pass`);
    },
    updateData(url: string, password: string, repassword: string) {
        const data = {
            password: password,
            repassword: repassword,
        };
        return instance.post(url, data);
    },
};

export const resetPassPageAPI = {
    sendPassword(email: string) {
        const url = `/data/auth/reset-pass`;
        const data = {
            email: email,
        };
        return instance.post(url, data);
    },
};

export const loginPageAPI = {
    getData() {
        return instance.get(`auth/setup`);
    },
    async loadInfo(url: string, params: any = {}): Promise<any> {
        if ('undefined' === typeof params) params = {};
        const urlParams = qs.stringify(params);
        const response = await instance.get(url + (urlParams ? '?' + urlParams : ''));

        return response?.data ?? {};
    },
};

export const alertSubscriptionAPI = {
    getData(elementId: number, segmentId: number) {
        const url = '/data/notification/alert-rule/alert-rule-list-data';
        const data = {
            elementId: elementId,
            segmentId: segmentId,
        };
        return instance.post(url, data);
    },
    saveData(data: any) {
        const url = '/data/notification/alert-rule/save-alert-rule-list-data';
        return instance.post(url, data);
    },
    elementSubscriptionLoadData(elementId: number, segmentId: number) {
        const url = `/data/notification/element-subscription/subscription-info/element_id/${elementId}/segment_value_id/${segmentId}`;
        return instance.get(url);
    },
    elementSubscriptionSaveData(elementId: number, segmentId: number, data: any) {
        const url = `/data/notification/element-subscription/save-subscription/element_id/${elementId}/segment_value_id/${segmentId}`;
        return instance.post(url, data);
    },
    subscribedUsers(
        alertRuleId: number,
        isReport: string,
        elementId: number,
        segmentId: number,
        userToAdd?: number,
        notificationLine?: string,
    ) {
        let params = {
            alert_rule_id: alertRuleId,
            elementId: elementId,
            segment_value_id: segmentId,
            report_ind: isReport,
            ajax: 'Y',
        };

        if (typeof userToAdd != 'undefined') {
            params = {
                ...params,
                ...{
                    add_user: userToAdd,
                    notif_line: notificationLine,
                },
            };
        }

        const url = `/data/notification/alert-rule/user-list`;
        return instance.get(url + '?' + qs.stringify(params));
    },
};

export const dimensionAPI = {
    getData(elementId: number, segmentId: number, alertRuleId: number, showAll: boolean, userId: number) {
        const url = `/data/notification/alert-rule/alert-rule-dimension-data`;
        const data: any = {
            elementId: elementId,
            segmentId: segmentId,
            alertRuleId: alertRuleId,
            showAll: showAll ? 'Y' : 'N',
        };
        if (userId) data.userId = userId;

        return instance.post(url, data);
    },
};

export const distributionListAPI = {
    includeInBurstData(elementId: number, segmentId: number) {
        const url = `/data/notification/burst/include-in-burst-popup/element/${elementId}/segment/${segmentId}`;
        return instance.get(url);
    },
    includeInBurstCreate(elementId: number, segmentId: number, burstOption: string) {
        const url = `/data/notification/burst/tile-new-burst`;
        const data = {
            tile_favorite_id: '',
            tile_element_id: elementId,
            tile_sv_id: segmentId,
            burst: burstOption,
        };
        return instance.post(url, data);
    },
};

export const triggerAPI = {
    async getDependenciesList(triggerEventId: number) {
        const url = `data/editor/trigger/${triggerEventId}/list-dep-objects/`;
        return await instance.get(url);
    },
    collectOptions(triggerEventId: number) {
        const url = `data/editor/trigger/${triggerEventId}/collect-options/`;
        return instance.get(url);
    },
    deleteEventData(triggerEventId: number) {
        const url = `data/editor/trigger/${triggerEventId}/delete-event-data/`;
        return instance.get(url);
    },
    showDeleteData(triggerEventId: number, data: any) {
        const url = `data/editor/trigger/${triggerEventId}/show-delete-data/`;
        return instance.post(url, data);
    },
    deleteElementsData(triggerEventId: number, data: any) {
        const url = `data/editor/trigger/${triggerEventId}/delete-elements-data/`;
        return instance.post(url, data);
    },
    queueEvent(triggerEventId: number, data: any) {
        const url = `/data/service/event/trigger/${triggerEventId}`;
        return instance.post(url, data);
    },
    getLastRun(triggerEventId: number, lastRunId: number | string) {
        const date = new Date();
        const data = { last_run_id: lastRunId };
        const url = `data/editor/trigger/${triggerEventId}/get-last-run/${date.getTime()}/`;
        return instance.post(url, data);
    },
    getElements4list(triggerEventId: number) {
        const url = `data/editor/trigger/${triggerEventId}/get-elements-4-list/`;
        return instance.get(url);
    },
    getDatasets4list(triggerEventId: number) {
        const url = `data/editor/trigger/${triggerEventId}/get-datasets-4-list/`;
        return instance.get(url);
    },
    abortCollection(triggerEventId: number, restart: boolean) {
        const url = `data/editor/trigger/${triggerEventId}/abort-collection/${restart ? 'Y' : 'N'}/`;
        return instance.get(url);
    },
};

export const burstAPI = {
    async getEnabledState(notificationScheduleDistributionId: number) {
        const url = `/data/notification/burst/${notificationScheduleDistributionId}/get-enabled-state`;
        return await instance.get(url);
    },

    async getSubscribedUsers(notificationScheduleDistributionId: number, params: any = {}) {
        if ('undefined' === typeof params) params = {};
        const urlParams = qs.stringify(params);
        const url = `/data/notification/burst/${notificationScheduleDistributionId}/get-subscribed-users${
            urlParams ? '?' + urlParams : ''
        }`;
        return await instance.get(url);
    },
    async getPreview(notificationScheduleDistributionId: number) {
        const url = `/data/notification/burst/${notificationScheduleDistributionId}/preview`;
        return await instance.get(url);
    },
    async disableDistributionList(notificationScheduleDistributionId: number) {
        const url = `/data/notification/burst/${notificationScheduleDistributionId}/disable`;
        return await instance.post(url);
    },
    async enableDistributionList(notificationScheduleDistributionId: number, notificationScheduleId: number) {
        const url = `/data/notification/burst/${notificationScheduleDistributionId}/ns/${notificationScheduleId}/enable`;
        return await instance.post(url);
    },
};

export const DenyPageAPI = {
    async getData(params: any) {
        const response = await instance.post('data/auth/deny', params);

        return response?.data ?? {};
    },
    requireTilesAccess(elementId: number, segmentValueId: number) {
        const data = {
            element_id: elementId,
            segment_value_id: segmentValueId,
        };

        return instance.post(`data/auth/require-tile-access`, data);
    },
};

export const AdminLogAPI = {
    async getData(params: any) {
        const response = await instance.post('data/admin/log', params);

        return response?.data?.data ?? {};
    },
};

export const pluginQueryBuilderAPI = {
    async getQueryBuilderData(
        connectionProfileId: number,
        reportId: number,
        itemId: number,
        itemType: string,
        refresh?: boolean,
    ) {
        const url = `/data/editor/index/plugin-fetch-fields`;
        const data = {
            query: 'command = getSchema',
            report: reportId,
            item_id: itemId,
            item_type: itemType,
            profile: connectionProfileId,
            force_refresh: refresh,
        };
        return await instance.post(url, data);
    },

    saveFieldTypeOverrides(reportId: number, overrides: any, aliases: any) {
        const url = `/data/editor/external-reference/${reportId}/save-type-overrides`;
        const data = {
            types: overrides ?? {},
            aliases: aliases ?? {},
        };
        return instance.post(url, data);
    },
};

export const AlertUserMapAccessAPI = {
    getAlertRuleUserMapInfo(alertRuleId: number) {
        const url = `data/editor/alert-rule/${alertRuleId}/get-alert-rule-user-map-info`;
        return instance.get(url);
    },
    loadColumns(alertRuleId: number, datasetId: number) {
        const url = `data/editor/alert-rule/${alertRuleId}/load-columns/${datasetId}`;
        return instance.get(url);
    },
    updateAlertRuleUserMap(alertRuleId: number, params: any) {
        const url = `data/editor/alert-rule/${alertRuleId}/update-alert-rule-user-map`;
        const data = params ?? {};
        return instance.post(url, data);
    },
};

export const RebuildUserAccessAPI = {
    create() {
        return instance.get(`/data/editor/index/ua-rebuild`);
    },
};

export const documentsAPI = {
    async getGlobalDocuments() {
        const response = await instance.get(`/data/document/global`);
        return response.data.data;
    },
};
