import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { processUrlBackwardCompatibility } from 'components/backward-compatibility-router/index';
import Error404Page from 'app/error/Error404Page';

export default function RedirectEditPage() {
    const navigate = useNavigate();
    const location = useLocation();
    let params = useParams();
    const [newUrl, setNewUrl] = useState<string | null>(null);

    useEffect(() => {
        let entityKey = params.entityType ?? '';
        if (!entityKey) {
            if (location.pathname.includes('/editor/index/index/element/')) {
                entityKey = 'metric';
            }
        }

        const processedUrl = processUrlBackwardCompatibility('edit', entityKey, location, params);
        setNewUrl(processedUrl);
        if (processedUrl) {
            navigate(processedUrl);
            return;
        }
    }, []);

    if (newUrl === '') return <Error404Page />;

    return null;
}
